frappe.ui.form.Sidebar = class Sidebar extends frappe.ui.form.Sidebar {
	refresh() {
		super.refresh()
		let sidebar_toggle = this.page.wrapper.find(".page-head").find(".sidebar-toggle-btn")
		if (this.frm.doc.__islocal) {
			sidebar_toggle.toggle(false)
		}
		else {
			sidebar_toggle.toggle(true)
		}
	}
}
